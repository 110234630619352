
<template>
  <div class="main">
    <div class="top">
      <div class="log">
        <img src="@/assets/images/logo.png" alt="" />
      </div>
      <div class="slogan">
        <img src="@/assets/images/wenzi.png" alt="" />
      </div>
      <div class="downLoad">
        <div class="button">
          <!-- 安卓下载 -->
          <!-- <a href="https://a.app.qq.com/o/simple.jsp?pkgname=org.panda.words">
            <img src="@/assets/images/anniu1.png" alt=""
          /></a> -->
          <button class="downButton1" id="downloadButton1"> </button>
              <button class="downButton123" >  </button>
            <!-- <img src="@/assets/images/anniu1.png" alt="" id="downloadButton" -->
            <!-- /> -->

          <!-- <button id="launch-btn">

          </button> -->
        </div>
        <div class="code">
          <!-- ios下载 -->
          <img src="@/assets/images/code.png" alt="" />
          <p>扫码下载></p>
        </div>
      </div>
      <div class="friends">
        <div class="buttonFri">
          <img src="@/assets/images/juese.png" alt="" class="activeFriends" />
        </div>
        <div class="leftAnimal">
          <div><img src="@/assets/images/dongwu/gou1.png" alt="" /></div>
          <img src="@/assets/images/dongwu/hou.png" alt="" class="hou" />
          <img src="@/assets/images/dongwu/ji.png" alt="" class="ji" />
          <img src="@/assets/images/dongwu/laoshu.png" alt="" />
          <img
            src="@/assets/images/dongwu/maotouying.png"
            alt=""
            class="maotouying"
          />
        </div>
        <div class="rightAnimal">
          <img src="@/assets/images/dongwu/gou2.png" alt="" class="gou" />
          <img src="@/assets/images/dongwu/niu.png" alt="" class="niu" />
          <img src="@/assets/images/dongwu/tuzi.png" alt="" />
          <img src="@/assets/images/dongwu/xiong.png" alt="" class="xiong" />
          <img src="@/assets/images/dongwu/yang.png" alt="" class="yang" />
        </div>
      </div>
    </div>

    <div class="introduce">
      <div class="intro1 hotActive">
        <div class="hotTitle">
          <img src="@/assets/images/-xing.png" alt="" />
          <p>热门活动</p>
          <img src="@/assets/images/xing-.png" alt="" />
        </div>
        <div class="hotPoster">
          <div class="oneHot">
            <img src="@/assets/images/groupstudy.png" alt="" />
          </div>
          <div class="oneHot">
            <img src="@/assets/images/classbattle.png" alt="" />
          </div>
        </div>
      </div>
      <div class="intro2">
        <div class="title2">
          <img src="@/assets/images/-xing.png" alt="" />
          <p>3v3单词对战</p>
          <img src="@/assets/images/xing-.png" alt="" />
        </div>
        <div class="content2">
          <div class="paragraph1 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              组建战队，集结智慧
            </p>

            组建战队，集结智慧召集你的伙伴们，组建一只战斗力爆表的熊猫战队，
            与对手展开智慧的较量。
          </div>
          <div class="paragraph3 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              团队协作，展现默契
            </p>

            和队友一起,答词协作,每一次答对,每一次协作都是你们团队团队默契的火花!
          </div>
        </div>
        <div class="pic2">
          <img src="@/assets/images/3v3.png" alt="" />
        </div>
      </div>
      <div class="intro1">
        <div class="title1">
          <img src="@/assets/images/-xing.png" alt="" />
          <p>AI学习助手</p>
          <img src="@/assets/images/xing-.png" alt="" />
        </div>
        <div class="content1">
          <div class="paragraph1 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              AI外教，角色聊天
            </p>
            你的专属英语助手24/7在线！无论是优化翻译、口语实战演练还是打磨句子结构，它都能为你提供专业指导。
          </div>
          <div class="paragraph3 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              十项全能，Penny外教
            </p>

            全方位覆盖听说读写，帮你扫清英语学习路上的所有障碍，轻松晋级英语达人殿堂
          </div>
        </div>
        <div class="pic1">
          <img src="@/assets/images/ai.png" alt="" />
        </div>
      </div>
      <div class="intro2">
        <div class="title2">
          <img src="@/assets/images/-xing.png" alt="" />
          <p>智记趣味学</p>
          <img src="@/assets/images/xing-.png" alt="" />
        </div>
        <div class="content2">
          <div class="paragraph1 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
             智能记忆，趣味复习
            </p>

            智能记忆算法，趣味复习模式，用各种任务将学习的各个环节串联起来。
          </div>
          <div class="paragraph2 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              任务串联，背词有趣
            </p>

            每天完成任务，便可完成学习目标，背单词也能如此趣味横生！ <br />
          </div>
        </div>
        <div class="pic2">
          <img src="@/assets/images/zhiji.png" alt="" />
        </div>
      </div>
      <div class="intro1">
        <div class="title1">
          <img src="@/assets/images/-xing.png" alt="" />
          <p>成长学习小组</p>
          <img src="@/assets/images/xing-.png" alt="" />
        </div>
        <div class="content1">
          <div class="paragraph1 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              习惯养成
            </p>
            从佛系到学霸，我们一路陪伴，培养高效学习习惯。
            每天亮相，坚持监督，一起走好每一步。
          </div>
          <div class="paragraph2 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              友好互助
            </p>

            这里汇集大咖，从容面对挑战，展现自己的光芒。
            不论何地，跨足达宝学霸行列！
          </div>
        </div>
        <div class="pic1">
          <img src="@/assets/images/xiaozu.png" alt="" />
        </div>
      </div>
      <div class="intro2">
        <div class="title2">
          <img src="@/assets/images/-xing.png" alt="" />
          <p>智慧校友圈</p>
          <img src="@/assets/images/xing-.png" alt="" />
        </div>
        <div class="content2">
          <div class="paragraph1 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              校友联盟，顶峰相见
            </p>

            通过校友圈迅速找到你的老同学，建立属于自己的校友圈，与旧日同窗再续前缘！
          </div>
          <div class="paragraph2 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              校友PK，趣味UP
            </p>

            挑战学校、对决校友，背单词也能如此趣味横生！ <br />
            你追我赶，同心升级；欢乐竞速，相互鼓励~
          </div>
        </div>
        <div class="pic2">
          <img src="@/assets/images/xiaoyoubang.png" alt="" />
        </div>
      </div>
      <div class="intro1">
        <div class="title1">
          <img src="@/assets/images/-xing.png" alt="" />
          <p>多种学习模式</p>
          <img src="@/assets/images/xing-.png" alt="" />
        </div>
        <div class="content1">
          <div class="paragraph1 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              单词测试
            </p>
            「五大训练题型」看英选中/看中选英/听音辨义/
            读词训练/拼写训练，重词难词，逐个突破
          </div>
          <div class="paragraph2 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              趣味探索
            </p>

            趣味情景式单词闯关，收集遗忘单词字母，<br />
            助力伙伴打败BOSS，让复习变得so easy^
          </div>
        </div>
        <div class="pic1">
          <img src="@/assets/images/1.png" alt="" />
        </div>
      </div>
      <div class="intro2">
        <div class="title2">
          <img src="@/assets/images/-xing.png" alt="" />
          <p>海量词书,科学统计</p>
          <img src="@/assets/images/xing-.png" alt="" />
        </div>
        <div class="content2">
          <div class="paragraph1 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              真题核心词&考纲词
            </p>

            小学/初中/高中/四六级/考研/托福/ 雅思/专四/专八/医学/体育/计算机....
          </div>
          <div class="paragraph2 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              图表统计系统
            </p>

            累计学习量：每日已学/模糊/遗忘率 <br />
            遗忘曲线：反应每个阶段你对已学单词的遗忘率
          </div>
        </div>
        <div class="pic2">
          <img src="@/assets/images/2.png" alt="" />
        </div>
      </div>
      <div class="intro3">
        <div class="title3">
          <img src="@/assets/images/-xing.png" alt="" />
          <p>益智复习模式</p>
          <img src="@/assets/images/xing-.png" alt="" />
        </div>
        <div class="content1">
          <div class="paragraph1 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              正向激励学习
            </p>

            用各种任务将学习的各个环节串联起来， 每天完成任务，便可完成学习目标
          </div>
          <div class="paragraph2 paragraph">
            <p>
              <span><img src="@/assets/images/xing.png" alt="" /></span>
              排行榜
            </p>

            背单词也能上「努力排行榜」，<br />
            让你的努力一直被看见
          </div>
        </div>
        <div class="pic3">
          <img src="@/assets/images/3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="more">
      <div class="moreTitle">
        <img src="@/assets/images/-xing.png" alt="" />
        <p>看看更多</p>
        <img src="@/assets/images/xing-.png" alt="" />
      </div>
      <div class="swiper">
        <el-carousel
          :interval="4000"
          type="card"
          class="card"
          ref="cardShow"
          arrow="never"
        >
          <el-carousel-item
            v-for="(item, index) in picList"
            :key="index"
            class="cardItem"
          >
            <img class="medium" :src="item.picUrl" alt="" />
          </el-carousel-item>
        </el-carousel>
        <img
          src="@/assets/images/houtui.png"
          alt=""
          @click="arrowClick('left')"
          class="leftArrow"
        />
        <img
          src="@/assets/images/wanqing.png"
          alt=""
          @click="arrowClick('right')"
          class="rightArrow"
        />
      </div>
    </div>
    <div class="footer">
      <div class="phone">
        <!-- <img src="@/assets/images/fff.png" alt="" /> -->
        <div class="connect">
          <p>联系我们</p>
          官方QQ群：712390854
        </div>
        <div class="logoSmall">
          <img src="@/assets/images/ic_launcher(3).png" alt="" />
          <p>熊猫在学</p>
        </div>
        <div class="touch">
          <img src="@/assets/images/企业微信截图.png" alt="" />
          <p>联系合作</p>
        </div>
        <div class="kftouch">
          <img src="@/assets/images/kefucode.png" alt="" />
          <p>联系客服</p>
        </div>
      </div>
      <div class="cominfo">
        <div class="company">
          <div class="text2">
            趣味背词体验，伙伴召唤系统。益智单词闯关，排行激励机制。合理安排时间，享受健康生活。在熊猫在学，遇见美好自己
          </div>
          <div class="text1">
            如果你在产品过程中，如遇到任何问题或意见，请通过以下方式联系我们，让我们解决与完善，熊猫在学愿陪伴你一起越来越好~
          </div>

          <div class="right">
            <a
              href="https://beian.miit.gov.cn"
              >鄂ICP证：鄂B2-20200287
            </a>
            |
            <a href="https://beian.miit.gov.cn"
              >鄂ICP备19017498号</a
            >
          </div>
          <div style="cursor: pointer">
            <span>©盘达学趣（武汉）科技有限公司 - </span>
            <span @click="jump()">关于我们</span> |
            <span @click="toConnect()">联系方式</span>
          </div>
          <p>
            工商注册地址：武汉东湖新技术开发区关东街道高新二路北辰光谷里二期8幢8号楼A单元24层元空间088
          </p>
        </div>
      </div>
    </div>
    <div class="mobail">
      <div class="top2">
        <div class="logo2">
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="slogan2">
          <img src="../assets/images/yidongduan/Slo.png" alt="" />
        </div>
        <div class="downLoad2">
          <!-- <wx-open-launch-app
    id="launch-btn"
    class="launch-btn"
    appid="wxa887ad8e710557eb"
    >
    <script type="text/wxtag-template">
     <style>.btn {height: 960rem;}</style>
        <div class="btn">打开app</div>
    </script>
</wx-open-launch-app> -->

          <div class="button2" id="downloadButton2">
            <!-- <div>{{ $device }}</div> -->
            <!-- <a
              v-if="$device == 'Android'"
              href="https://a.app.qq.com/o/simple.jsp?pkgname=org.panda.words"
            > -->
            <!-- <img src="@/assets/images/anniu1.png" alt="" id="downloadButton" -->
            <!-- /> -->
            <!-- </a> -->
            <button class="downButton11" ></button>
             <button class="downButton12" ></button>

            <!-- <button v-if="$device == 'iOS'" class="downButton22"></button> -->
            <!-- <img
              v-if="$device == 'iOS'"
              src="@/assets/images/anniu2.png"
              alt=""
              id="downloadButton"
            />-->
          </div>
          <!-- <div class="code2"> -->
            <!-- <img src="@/assets/images/code.png" alt="" /> -->
            <!-- <p>扫码下载></p> -->
          <!-- </div> -->
        </div>
        <div class="juese">
          <!-- <div class="duiHuaKuang">
            <div>好消息!凭此码可在熊猫在学</div>
            <div>领取免费1000单词资格啦!</div>
            <div>圣才渠道吗:VIAVUL</div>
          </div> -->
          <img src="../assets/images/yidongduan/juese.png" alt="" />
        </div>
      </div>
      <div class="introduce2">
        <div class="page1 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>热门活动</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="hotActive2">
            <div class="onePoster2 posterLeft">
              <img src="@/assets/images/yidongduan/groupStudyMb.png" alt="" />
            </div>
            <div class="onePoster2 posterRight">
              <img src="@/assets/images/yidongduan/schoolBattleMb.png" alt="" />
            </div>
          </div>
        </div>

       <div class="page1 page2 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>3v3单词对战</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="msgBox">
            <div class="part2">
              <img src="@/assets/images/3v3.png" alt="" />
            </div>
            <div class="part1">
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div> 组建战队，集结智慧</div>
                  <div>
                     和队友一起,答词协作,每一次答对,每一次协作都是你们团队团队默契的火花! 
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>团队协作，展现默契</div>
                  <div>
                    挑战学校、对决校友，背单词也能如此趣味横生！ <br />
                    你追我赶，同心升级；欢乐竞速，相互鼓励~
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page1 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>AI学习助手</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="msgBox">
            <div class="part1">
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>AI外教，角色聊天</div>
                  <div>
                    你的专属英语助手24/7在线！无论是优化翻译、口语实战演练还是打磨句子结构，它都能为你提供专业指导。
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>十项全能，Penny外教</div>
                  <div>
                    全方位覆盖听说读写，帮你扫清英语学习路上的所有障碍，轻松晋级英语达人殿堂
                  </div>
                </div>
              </div>
            </div>
            <div class="part2">
              <img src="@/assets/images/xiaozu.png" alt="" />
            </div>
          </div>
        </div>
        <div class="page1 page2 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>智记趣味学</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="msgBox">
            <div class="part2">
              <img src="@/assets/images/xiaoyoubang.png" alt="" />
            </div>
            <div class="part1">
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>智能记忆，趣味复习</div>
                  <div>
                    智能记忆算法，趣味复习模式，用各种任务将学习的各个环节串联起来。
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>任务串联，背词有趣</div>
                  <div>
                    每天完成任务，便可完成学习目标，背单词也能如此趣味横生！ <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page1 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>成长学习小组</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="msgBox">
            <div class="part1">
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>习惯养成</div>
                  <div>
                    从佛系到学霸，我们一路陪伴，培养高效学习习惯。
                    每天亮相，坚持监督，一起走好每一步。
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>友好互助</div>
                  <div>
                    这里汇集大咖，从容面对挑战，展现自己的光芒。
                    不论何地，跨足达宝学霸行列！
                  </div>
                </div>
              </div>
            </div>
            <div class="part2">
              <img src="@/assets/images/xiaozu.png" alt="" />
            </div>
          </div>
        </div>
        <div class="page1 page2 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>智慧校友圈</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="msgBox">
            <div class="part2">
              <img src="@/assets/images/xiaoyoubang.png" alt="" />
            </div>
            <div class="part1">
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>校友联盟，顶峰相见</div>
                  <div>
                    通过校友圈迅速找到你的老同学，建立属于自己的校友圈，与旧日同窗再续前缘！
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>校友PK，趣味UP</div>
                  <div>
                    挑战学校、对决校友，背单词也能如此趣味横生！ <br />
                    你追我赶，同心升级；欢乐竞速，相互鼓励~
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page1 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>多种学习模式</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="msgBox">
            <div class="part1">
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>单词测试</div>
                  <div>
                    「五大训练题型」看英选中/看中选英/听音辨义/
                    读词训练/拼写训练，重词难词，逐个突破
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>趣味探索</div>
                  <div>
                    趣味情景式单词闯关，收集遗忘单词字母，
                    助力伙伴打败BOSS，让复习变得so easy^
                  </div>
                </div>
              </div>
            </div>
            <div class="part2">
              <img src="@/assets/images/1.png" alt="" />
            </div>
          </div>
        </div>
        <div class="page1 page2 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>海量词书,科学统计</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="msgBox">
            <div class="part2">
              <img src="@/assets/images/2.png" alt="" />
            </div>
            <div class="part1">
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>真题核心词&考纲词</div>
                  <div>
                    小学/初中/高中/四六级/考研/托福/
                    雅思/专四/专八/医学/体育/计算机....
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>图表统计系统</div>
                  <div>
                    累计学习量：每日已学/模糊/遗忘率 <br />
                    遗忘曲线：反应每个阶段你对已学单词的遗忘率
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page1 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>益智复习模式</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="msgBox">
            <div class="part1">
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>正向激励学习</div>
                  <div>
                    用各种任务将学习的各个环节串联起来，
                    每天完成任务，便可完成学习目标
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/images/xing.png" alt="" />
                <div class="msg">
                  <div>排行榜</div>
                  <div>
                    背单词也能上「努力排行榜」，<br />
                    让你的努力一直被看见
                  </div>
                </div>
              </div>
            </div>
            <div class="part2">
              <img src="@/assets/images/3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="footer2">
        <div class="page1 page4 page">
          <div class="title">
            <img src="@/assets/images/-xing.png" alt="" />
            <p>看看更多</p>
            <img src="@/assets/images/xing-.png" alt="" />
          </div>
          <div class="msgBox22">
            <!-- <van-swipe
              class="my-swipe"
              :autoplay="3000"
              indicator-color="white"
            >
              <van-swipe-item v-for="(item, index) in picList" :key="index">
                <img :src="item.picUrl" alt="" />
              </van-swipe-item>
            </van-swipe> -->

            <van-swipe
              class="my-swipe"
              :autoplay="3000"
              indicator-color="white"
              show-indicators
              ref="swipe2"
            >
              <van-swipe-item v-for="(item, index) in picList" :key="index">
                <img :src="item.picUrl" alt="" />
              </van-swipe-item>
            </van-swipe>

            <!-- <el-carousel height="150px">
              <el-carousel-item v-for="item in 4" :key="item">
                <h3>{{ item }}</h3>
              </el-carousel-item>
            </el-carousel> -->
          </div>
        </div>
        <div class="info">
          <div class="box">
            <div>官方QQ群：712390854</div>
            <div>
              <a
                href="https://beian.miit.gov.cn"
                >鄂ICP证：鄂B2-20200287
              </a>
              |
              <a href="https://beian.miit.gov.cn"
                >鄂ICP备19017498号</a
              >
            </div>
            <div>
              <span @click="jump()">关于我们</span> |
              <span @click="toConnect()">联系方式</span>
              <span>©盘达学趣（武汉）科技有限公司</span>
            </div>

            <p>
              工商注册地址：武汉东湖新技术开发区关东街道高新二路北辰光谷里二期8幢8号楼A单元24层元空间088
            </p>
            <!-- <div>{{ $device }}</div> -->
          </div>
        </div>
        <div class="codeBox">
          <div class="code2">
            <img src="../assets/images/yidongduan/qrcode.png" alt="" />
            <p>联系合作</p>
          </div>
          <div class="code2">
            <img src="@/assets/images/kefucode.png" alt="" />
            <p>联系客服</p>
          </div>
        </div>
        <div>
          <!-- <img src="@/assets/images/fff.png" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<!-- 建议直接引用文档中js文件地址（cdn加速链接），以保证文件能及时更新-->
 <script type="text/javascript" charset="UTF-8" src="https://web.cdn.openinstall.io/openinstall.js"></script>
<!-- //建议采用在线引用的方式，以便于及时获取最新文件
  <script>
// (function (b, a, e, h, f, c, g, s) {
//   b[h] =
//     b[h] ||
//     function () {
//       (b[h].c = b[h].c || []).push(arguments);
//     };
//   b[h].s = !!c;
//   g = a.getElementsByTagName(e)[0];
//   s = a.createElement(e);
//   s.src = "//s.union.360.cn/" + f + ".js";
//   s.defer = !0;
//   s.async = !0;
//   g.parentNode.insertBefore(s, g);
// })(window, document, "script", "_qha", 531218, false);
// </script>
-->
<script>
import axios from "axios";
export default {
  name: "words",
  data() {
    return {
      // isShowWxOpenApp:false,
      picList: [
        // {
        //   picUrl: require("@/assets/images/jietu/Screenshot_20230109_173640_org.panda.words(1).png"),
        // },
        {
          picUrl: require("@/assets/images/jietu/Screenshot_20230110_173031_org.panda.words(1).png"),
        },
        {
          picUrl: require("@/assets/images/jietu/Screenshot_20230327_110402_org.panda.words(1).png"),
        },
        {
          picUrl: require("@/assets/images/jietu/Screenshot_20230327_110411_org.panda.words(1).png"),
        },
        // {
        //   picUrl: require("@/assets/images/jietu/Screenshot_20230327_111606_org.panda.words(1).png"),
        // },
        {
          picUrl: require("@/assets/images/jietu/Screenshot_20230327_111737_org.panda.words(1).png"),
        },
        {
          picUrl: require("@/assets/images/jietu/Screenshot_20230327_111738_org.panda.words(1).png"),
        },
        {
          picUrl: require("@/assets/images/jietu/Screenshot_20230327_111739_org.panda.words(1).png"),
        },
      ],
    };
  },
  //   watch:{
  // 	dataList(val){
  // 		var _this = this;
  // 		setTimeout(() => {
  //    _this.$refs.cardShow.resize();
  // 	}, 500);
  // 	}
  // },
  methods: {
    jump() {
      this.$router.push("/about");
    },
    toConnect() {
      this.$router.push("/connect");
    },
    arrowClick(val) {
      if (val === "right") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },

    //       wxlaunch(){
    //         const btn = document.getElementById('launch-btn');

    // btn.addEventListener('launch', function (e) {
    //   console.log('success');
    // });
    // btn.addEventListener('error', function (e) {
    //   console.log('fail', e.detail);
    // });

    //       },
    makeQrcode() {
      axios
        .get("https://api.pwmqr.com/qrcode/create/", {
          // 传递的参数
          // params: {
          //    // 账号
          //    url:encodeURI('https://pandaxuequ.com.cn/#/'),
          //    down:1
          // },
          // responseType: 'blob'
          // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
        })
        .then((res) => {
          console.log(res, "qrcode");
          let blob = new Blob([res.data]); // 返回的文件流数据
          let url = window.URL.createObjectURL(blob); // 将他转化为路径
          this.img = url;
          this.urlToBase64(url).then((res) => {
            // 转化后的base64图片地址
            this.picData = res;
            // console.log('base64', res)
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    first() {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "//res.cdn.openinstall.io/openinstall.js";
      s.addEventListener(
        "load",
        () => {
          var data = OpenInstall.parseUrlParams(); //openinstall.js中提供的工具函数，解析url中的所有查询参数
          new OpenInstall(
            {
              /*appKey必选参数，openinstall平台为每个应用分配的ID*/
              appKey: "hww53l",
              /*可选参数，自定义android平台的apk下载文件名；个别andriod浏览器下载时，中文文件名显示乱码，请慎用中文文件名！*/
              //apkFileName : 'com.fm.openinstalldemo-v2.2.0.apk',
              /*可选参数，是否优先考虑拉起app，以牺牲下载体验为代价*/
              //preferWakeup:true,
              /*自定义遮罩的html*/
              //mask:function(){
              //  return "<div id='openinstall_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
              //},
              /*openinstall初始化完成的回调函数，可选*/
              onready: function () {
                var m = this,
                  button = document.getElementById("downloadButton1");
                button.style.visibility = "visible";

                /*在app已安装的情况尝试拉起app*/
                m.schemeWakeup();
                /*用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
                button.onclick = function () {
                  m.wakeupOrInstall();
                  return false;
                };
              },
            },
            data
          );
          // console.log(data,'data')
        },
        false
      );
      document.head.appendChild(s);
    },
    second() {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "//res.cdn.openinstall.io/openinstall.js";
      s.addEventListener(
        "load",
        () => {
          var data = OpenInstall.parseUrlParams(); //openinstall.js中提供的工具函数，解析url中的所有查询参数
          new OpenInstall(
            {
              /*appKey必选参数，openinstall平台为每个应用分配的ID*/
              appKey: "hww53l",
              /*可选参数，自定义android平台的apk下载文件名；个别andriod浏览器下载时，中文文件名显示乱码，请慎用中文文件名！*/
              //apkFileName : 'com.fm.openinstalldemo-v2.2.0.apk',
              /*可选参数，是否优先考虑拉起app，以牺牲下载体验为代价*/
              //preferWakeup:true,
              /*自定义遮罩的html*/
              //mask:function(){
              //  return "<div id='openinstall_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
              //},
              /*openinstall初始化完成的回调函数，可选*/
              onready: function () {
                var m = this,
                  button = document.getElementById("downloadButton2");
                button.style.visibility = "visible";

                /*在app已安装的情况尝试拉起app*/
                m.schemeWakeup();
                /*用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
                button.onclick = function () {
                  m.wakeupOrInstall();
                  return false;
                };
              },
            },
            data
          );
        },
        false
      );
      document.head.appendChild(s);
    },
  },
  created() {
    //  this.wxlaunch()
  },

  mounted() {
    this.first();
    this.second();
    // this.wxlaunch()
    // this.makeQrcode()
  },
};
</script>


<style lang="scss" scoped>
@media (min-width: 901px) {
  @keyframes bounce-down {
    25% {
      transform: translateY(-8px);
    }
    50%,
    100% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(8px);
    }
  }
  @keyframes bounce-up {
    25% {
      transform: translateY(8px);
    }
    50%,
    100% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-8px);
    }
  }

  .mobail {
    display: none;
  }
  .main {
    width: 100%;
    // height: auto;

    .top {
      width: 100%;
      height: 980rem;
      // position: absolute;
      background-image: url("~@/assets/images/bg1.png");
      background-size: 100% 100%;
      overflow: hidden;
      .log {
        width: 434rem;
        height: 278rem;
        margin: 50rem auto 0;
        img {
          width: 100%;
        }
      }
      .slogan {
        width: 423rem;
        height: 47rem;
        margin: 0 auto 0;
        img {
          width: 100%;
        }
      }
      .downLoad {
        display: flex;
        justify-content: space-between;
        width: 550rem;
        height: 182rem;
        margin: 50rem auto 0;
        .button {
          width: 300rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .downButton1 {
            width: 100%;
            height: 100rem;
            border-radius: 10rem;
            border-style: none;
            background-color: Transparent;
            background-image: url("~@/assets/images/onebtn.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
          }
             .downButton123 {
            width: 100%;
            height: 100rem;
            border-radius: 10rem;
            border-style: none;
            background-color: Transparent;
            background-image: url("~@/assets/images/openapp.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
          }
          .downButton2 {
            width: 100%;
            height: 100rem;
            border-radius: 10rem;
            border-style: none;
            background-color: Transparent;
            background-image: url("~@/assets/images/anniu2.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
          }
          img {
            margin-top: 9rem;
            width: 100%;
          }
        }
        .code {
          display: inline-block;
          position: relative;
          width: 181rem;
          img {
            width: 100%;
          }
          p {
            width: 181rem;
            text-align: center;
            font-size: 21rem;
            font-family: KingnamMaiyuanBeta;
            font-weight: normal;
            letter-spacing: 0px;
            color: #a08777;
          }
        }
      }
      .friends {
        margin-top: 10rem;
        width: 1920rem;
        height: 355rem;
        .buttonFri {
          // width: 1920rem;
          height: 355rem;

          .activeFriends {
            width: 110%;
            margin-top: 50rem;
            margin-left: -125rem;
            animation: bounce-down 2s linear infinite;
          }
        }
        .leftAnimal {
          margin-top: -950rem;
          margin-left: 15rem;
          width: 400rem;
          height: 400rem;
          position: relative;
          animation: bounce-up 2s linear infinite;
          img {
            margin-right: 14rem;
            margin-bottom: 14rem;
            width: 120rem;
          }
          .hou {
            position: absolute;
            top: 20rem;
            left: 280rem;
          }
          .ji {
            position: absolute;
            top: 180rem;
            left: 400rem;
          }
          .maotouying {
            position: absolute;
            top: 240rem;
            left: 120rem;
          }
        }
        .rightAnimal {
          margin-top: -380rem;
          margin-left: 1500rem;
          width: 400rem;
          height: 400rem;
          position: relative;
          animation: bounce-up 2s linear infinite;
          img {
            margin-right: 14rem;
            margin-bottom: 14rem;
            width: 120rem;
          }
          .yang {
            position: absolute;
            top: 300rem;
            right: 20rem;
          }
          .xiong {
            position: absolute;
            top: 200rem;
            right: 420rem;
          }
          .gou {
            position: absolute;
            top: 20rem;
            right: 620rem;
          }
          .niu {
            position: absolute;
            top: 10rem;
            right: 20rem;
          }
        }
      }
    }
    .introduce {
      width: 100%;
      height: 10020rem;
      // position: absolute;
      margin-top: -120rem;
      background-color: #fae8ba;
      z-index: 0;

      .hotActive {
        background-image: url("~@/assets/images/hotbeijing.png");
        background-size: contain;
        .hotTitle {
          margin: 120rem auto 0;
          width: 742rem;
          height: 70rem;
          white-space: nowrap;
          img {
            width: 199rem;
          }

          p {
            margin: 0 26rem;
            display: inline-block;
            font-family: KingnamMaiyuanBeta;
            font-size: 54rem;
            font-weight: normal;
            font-stretch: normal;
            line-height: 112rem;
            color: #7b5431;
          }
        }
        .hotPoster {
          display: flex;
          justify-content: space-between;
          margin: 120rem auto 0;
          width: 1600rem;
          height: 800rem;
          // background-color: pink;
          .oneHot {
            width: 700rem;
            height: 600rem;
            img {
              width: 100%;
            }
          }
        }
      }
      .paragraph {
        font-family: KingnamMaiyuanBeta;
        font-size: 38rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 67rem;
        letter-spacing: 0rem;
        color: #7b5431;
      }
      .intro1,
      .intro3 {
        position: relative;
        width: 100%;
        height: 980rem;

        .title1,
        .title3 {
          // position: absolute;
          margin-top: 100rem;
          margin-left: 283rem;
          width: 742rem;
          height: 50rem;
          white-space: nowrap;
          img {
            width: 199rem;
          }

          p {
            margin: 0 26rem;
            display: inline-block;
            font-family: KingnamMaiyuanBeta;
            font-size: 54rem;
            font-weight: normal;
            font-stretch: normal;
            line-height: 112rem;
            color: #7b5431;
          }
        }
        .content1 {
          position: absolute;
          margin-top: 113rem;
          margin-left: 157rem;
          width: 968rem;
          height: 687rem;
          background-image: url("~@/assets/images/baidi.png");
          background-size: contain;
          .paragraph1 {
            margin-top: 136rem;
            margin-left: 136rem;
            width: 700rem;
            height: 166rem;
          }
          .paragraph2 {
            margin-top: 45rem;
            margin-left: 136rem;
            width: 606rem;
            height: 168rem;
          }
           .paragraph3 {
            margin-top: 80rem;
            margin-left: 136rem;
            width: 606rem;
            height: 168rem;
          }
        }
        .pic1,
        .pic3 {
          position: absolute;
          margin-top: 62rem;
          margin-left: 1188rem;
          width: 586rem;
          img {
            width: 100%;
          }
        }
      }
      .intro2 {
        position: relative;
        width: 100%;
        height: 1080rem;
        .title2 {
          white-space: nowrap;
          // position: absolute;
          margin-top: 100rem;
          margin-left: 885rem;
          width: 857rem;
          height: 50rem;
          img {
            width: 199rem;
          }
          p {
            margin: 0 26rem;
            display: inline-block;
            font-family: KingnamMaiyuanBeta;
            font-size: 54rem;
            font-weight: normal;
            font-stretch: normal;
            line-height: 112rem;
            color: #7b5431;
          }
        }
        .content2 {
          position: absolute;
          margin-top: 262rem;
          margin-left: 765rem;
          width: 968rem;
          height: 687rem;
          background-image: url("~@/assets/images/baidi.png");
          background-size: contain;
          .paragraph1 {
            margin-top: 110rem;
            margin-left: 148rem;
            width: 600rem;
            height: 167rem;
          }
          .paragraph2 {
            margin-top: 40rem;
            margin-left: 148rem;
            width: 700rem;
            height: 166rem;
          }
          .paragraph3 {
            margin-top: 100rem;
            margin-left: 148rem;
            width: 700rem;
            height: 166rem;
          }
        }
        .pic2 {
          position: absolute;
          margin-top: 152rem;
          margin-left: 110rem;
          width: 586rem;
          img {
            width: 100%;
          }
        }
      }
    }
    .more {
      width: 100%;
      height: 1080rem;
      // position: absolute;
      // position: relative;
      // top: 4220rem;
      margin-top: -65rem;
      background-image: url("~@/assets/images/beijing3.png");
      background-size: 100% 100%;
      .moreTitle {
        width: 1645rem;
        height: 100rem;
        margin: 65rem auto 30rem;
        white-space: nowrap;
        text-align: center;
        p {
          margin: 0 26rem;
          display: inline-block;
          font-family: KingnamMaiyuanBeta;
          font-size: 54rem;
          font-weight: normal;
          font-stretch: normal;
          line-height: 112rem;
          color: #7b5431;
        }
      }
      .swiper {
        width: 1360rem;
        height: 810rem;
        margin: 90rem auto 0;
        // background-color: pink;
        position: relative;
        .card {
          height: 810rem;
          width: 1090rem;
          margin: 0 auto;
          .cardItem {
            height: 810rem;
            width: 396rem;
            margin-left: 70rem;
            img {
              width: 100%;
            }
          }
        }
        .leftArrow {
          position: absolute;
          top: 50%;
        }
        .rightArrow {
          position: absolute;
          left: 93%;
          top: 50%;
        }
      }
    }
    .footer {
      width: 100%;
      height: 276rem;
      // margin-top: 10rem;
      // position: absolute;
      // top: 5300rem;
      background-color: #e4b582;
      a {
        text-decoration: none;
        color: #fff;
      }
      .cominfo {
        background-color: #e4b582;
        width: 100%;
        height: 220rem;
        .company {
          width: 1288rem;
          margin: 0 auto;
          background-color: #e4b582;
          color: #fff;
          // font-family: KingnamMaiyuanBeta;
          font-size: 19rem;
          line-height: 38rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .text2 {
            width: 1100rem;
            height: 18rem;
            margin-bottom: 10rem;
            text-align: center;
            white-space: nowrap;
          }
          .text1 {
            white-space: nowrap;
            text-align: center;
          }
          .logo {
            height: 71rem;
            margin-top: 30rem;

            .logoPic {
              img {
                vertical-align: middle;
              }

              span {
                line-height: 70rem;
                height: 70rem;
                margin-left: 7rem;
                margin-right: 20rem;
              }
            }
            .right {
              margin-left: 20rem;
            }
          }
          // .address{
          //   width: 100%;

          // }
        }
      }
      .phone {
        width: 100%;
        height: 333rem;
        background-color: #e4b582;
        div {
          font-size: 36rem;
          font-family: KingnamMaiyuanBeta;
          line-height: 38rem;
          color: #7b5431;
          width: 370rem;
          height: 200rem;
          position: absolute;
          margin: 98rem 200rem 0 150rem;
          p {
            margin-bottom: 40rem;
          }
        }
        .logoSmall {
          margin-left: 1200rem;
          margin-top: 60rem;
          width: 175rem;
          img {
            width: 100%;
          }
          p {
            text-align: center;
          }
        }
        .touch {
          margin-left: 1400rem;
          margin-top: 60rem;
          width: 175rem;
          img {
            width: 100%;
          }
          p {
            text-align: center;
          }
        }
        .kftouch {
          margin-left: 1600rem;
          margin-top: 60rem;
          width: 175rem;
          img {
            width: 100%;
          }
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  @keyframes leftFloat {
    25% {
      transform: translateX(8px);
    }
    50%,
    100% {
      transform: translateX(0);
    }
    75% {
      transform: translateX(-8px);
    }
  }
  .main {
    .top {
      display: none;
    }
    .introduce {
      display: none;
    }
    .more {
      display: none;
    }
    .footer {
      display: none;
    }

    .mobail {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: #fae8ba;
      .top2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-image: url(../assets/images/yidongduan/beijing.png);
        background-repeat: no-repeat;
        background-size: cover;
        // background-size: contain;
        .logo2 {
          margin-top: calc(150rem * 2.56);
          img {
            width: calc(523rem * 2.56);
            object-fit: contain;
          }
        }
        .slogan2 {
          margin-top: calc(20rem * 2.56);
          img {
            width: calc(350rem * 2.56);
            object-fit: contain;
          }
        }
        .downLoad2 {
          display: flex;
          justify-content: center;
          // align-items: center;
          margin-top: 120rem;
          .launch-btn {
            width: 400rem;
            height: 200rem;
            background-color: #fff;
          }
          .button2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 50rem;
            .downButton11 {
              width: 600rem;
              height: 200rem;
              border-radius: 10rem;
              border-style: none;
              background-color: Transparent;
              background-image: url("~@/assets/images/onebtn.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              cursor: pointer;
            }
             .downButton12 {
              width: 600rem;
              height: 200rem;
              border-radius: 10rem;
              border-style: none;
              background-color: Transparent;
              background-image: url("~@/assets/images/openapp.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              cursor: pointer;
            }
            .downButton22 {
              width: 600rem;
              height: 200rem;
              border-radius: 10rem;
              border-style: none;
              background-color: Transparent;
              background-image: url("~@/assets/images/anniu2.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              cursor: pointer;
            }

            img {
              width: calc(300rem * 2.56);
              object-fit: contain;
            }
            a {
              margin-bottom: 50rem;
            }
          }
          .code2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: calc(200rem * 2.56);
              object-fit: contain;
            }
            p {
              margin-top: 15rem;
              font-size: 60rem;
              font-family: KingnamMaiyuanBeta;
              font-weight: normal;
              letter-spacing: 0px;
              color: #a08777;
            }
          }
        }
        .juese {
          display: flex;
          flex-direction: column;
          margin-top: 100rem;
          animation: bounce-up 2s linear infinite;

          @keyframes bounce-up {
            25% {
              transform: translateY(8px);
            }
            50%,
            100% {
              transform: translateY(0);
            }
            75% {
              transform: translateY(-8px);
            }
          }
          .duiHuaKuang {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-image: url(../assets/images/yidongduan/duihuakuang.png);
            background-repeat: no-repeat;
            background-size: contain;
            width: calc(454rem * 2.56 * 0.75);
            height: calc(308rem * 2.56 * 0.75);
            align-self: flex-end;
            margin-right: 50rem;
            div {
              // font-size: 40rem;
              margin: 10rem;
              color: #e46060;
            }
          }
          img {
            width: calc(750rem * 2.56);
            object-fit: contain;
          }
        }
      }
      .introduce2,
      .footer2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        // background-color: #fae8ba;
        .page {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding-top: 130rem;
          // background-color: #fae8ba;
          img {
            width: calc(160rem * 2.56);
            object-fit: contain;
          }
          p {
            margin: 0 26rem;
            font-family: KingnamMaiyuanBeta;
            font-size: 100rem;
            color: #7b5431;
          }
        }
        .page1 {
          .hotActive2 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 100rem;
            width: 1600rem;
            height: 1600rem;
            // background-color: pink;
            .onePoster2 {
              width: 1600rem;
              img {
                width: 100%;
              }
            }
            .posterLeft {
              // margin-left:-100rem;
              // animation: leftFloat 2s linear infinite;
            }
            .posterRight {
              // margin-left:100rem;
            }
          }
          .msgBox {
            display: flex;
            margin-top: 150rem;
            width: 100%;
            .part1 {
              background-image: url(../assets/images/yidongduan/di.png);
              background-repeat: no-repeat;
              background-size: contain;
              width: 1000rem;
              height: 1000rem;
              padding: 100rem;
              box-sizing: border-box;
              .item {
                display: flex;
                &:nth-child(2) {
                  margin-top: 100rem;
                }
                img {
                  width: 50rem;
                  object-fit: contain;
                  align-self: flex-start;
                  margin-top: 10rem;
                }
                .msg {
                  display: flex;
                  flex-direction: column;
                  font-family: KingnamMaiyuanBeta;
                  font-size: 60rem;
                  color: #7b5431;
                }
              }
            }
            .part2 {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 800rem;
                object-fit: contain;
              }
            }
          }
        }
        .page2 {
          .msgBox {
            .part1 {
              margin-left: 50rem;
            }
          }

          .part2 {
            // margin-right: 50rem;
            margin-left: 100rem;
          }
        }
        .page4 {
          .msgBox {
            // background-color: red;

            .my-swipe {
              // width: 1920rem;
              // width: 100%;
              // background-color: red;
              .van-swipe-item {
                display: flex;
                justify-content: center;

                // background-color: #39a9ed;

                img {
                  // width: 1000rem;
                  width: 70%;
                  // height: 800rem;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
      .footer2 {
        margin-top: 200rem;
        background-image: url(../assets/images/yidongduan/beijing2.png);
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        .msgBox22 {
          margin-top: 150rem;
          .my-swipe {
            width: 1920rem;
            // width: 100%;
            // background-color: red;
            .van-swipe-item {
              display: flex;
              justify-content: center;

              // background-color: #39a9ed;

              img {
                // width: 1000rem;
                width: 70%;
                // height: 800rem;
                object-fit: contain;
              }
            }
          }
        }
        .info {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: KingnamMaiyuanBeta;
          .box {
            margin: 300rem 10rem 10rem 20rem;

            display: flex;
            flex-direction: column;
            // align-items: center;
            font-size: 60rem;
            padding-top: 80rem;
            padding-bottom: 80rem;
            // .link {
            //   display: flex;
            //   flex-direction: column;
            // }
            a {
              text-decoration: none;
              color: #fff;
            }
            img {
              width: 400rem;
              object-fit: contain;
              margin-left: 20rem;
            }
          }
        }
        .codeBox {
          width: 1400rem;
          display: flex;
          justify-content: space-between;
          .code2 {
            width: 600rem;
            img {
              width: 100%;
            }
            p {
              text-align: center;
              font-family: KingnamMaiyuanBeta;
            }
          }
        }
      }
    }
  }
}
</style>
