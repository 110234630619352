import Vue from "vue";
import VueRouter from "vue-router";
import words from "../views/words.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "words",
    component: words,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/connect",
    name: "connect",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/connect.vue"),
    meta: {
      title: "联系我们",
    },
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsDetail.vue"),
    meta: {
      title: "熊猫在学登顶App Store详情",
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
