import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/text/text.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);

Vue.config.productionTip = false;
Vue.use(ElementUI);

Vue.prototype.$device = (function () {
  if (/(Android)/i.test(navigator.userAgent)) {
    return "Android";
  } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return "iOS";
  } else if (/(shengcaidianzishu)/i.test(navigator.userAgent)) {
    return "圣才";
  } else {
    return "other";
  }
  // return "other";
})();

Vue.config.ignoredElements = [
  'wx-open-launch-app',
];

router.beforeEach((to,from,next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
